<template>
  <div id="projects_layout">
    <section class="">
      <div id="projects_table">
        <div id="projects_tabletop" class="row wide">
          <div class="padding-xy-4 padding-x-0-desktop padding-y-5-tablet">
            <div class="columns is-multiline is-mobile is-vcentered">
              <div
                v-if="(profile.type == 'jfe') && (profile.hasJfeAdminPermission)"
                class="column is-narrow">
                <b-field style="min-width: 250px; margin-bottom: 0">
                  <autocomplete-single
                    v-model="selectedLicenseeId"
                    class="field no-icon wrap-item"
                    placeholder="Licensee"
                    icon="id-badge"
                    :open-on-focus="true"
                    :items="licensees"
                    text-field="name"
                    value-field="id"
                    @change.native="resetLicensee()" />
                </b-field>
              </div>
              <div class="column">
                <b-field>
                  <b-taginput
                    v-model="searchTerms"
                    placeholder="Search..."
                    icon="search"
                    type="is-dark"
                    rounded />
                </b-field>
              </div>
            </div>
          </div>
        </div>
        <b-table
          class="scrollable clickable-row"
          :data="invoicesFiltered"
          :mobile-cards="false"
          :hoverable="true"
          paginated
          :per-page="50"
          :loading="loadingInvoices"
          @click="goToInvoice"
          @page-change="scrollTop">
          <template slot="empty">
            <div class="padding-xy-6 has-text-centered">
              No invoices
            </div>
          </template>

          <b-table-column v-slot="props" field="licensee" label="Licensee" :visible="(profile.type == 'jfe') && (profile.hasJfeAdminPermission) ? true : false">
            {{ licenseesById[props.row.licenseeId].name }}
          </b-table-column>
          <b-table-column v-slot="props" field="invoiceNumber" label="Invoice Number">
            {{ props.row.invoiceCodeNumber }}
          </b-table-column>
          <b-table-column v-slot="props" field="invoiceCreateDate" label="Invoice Date" sortable>
            {{ props.row.invoiceCreateDate | moment("M/D/YY") }}
          </b-table-column>
          <b-table-column v-slot="props" field="invoiceDueDate" label="Due Date" sortable>
            <span :class="{'has-text-danger' : daysOverdue(props.row) > 30 && !props.row.paid }">{{ momentDueDate(props.row.invoiceDueDate) }}</span>
          </b-table-column>

          <b-table-column field="sent" label="Sent">
            <template v-slot:header="{ column }">
              <span style="margin-right: 3px;">{{ column.label }}</span>
              <b-dropdown :mobile-modal="false">
                <b-button
                  slot="trigger"
                  icon-right="filter"
                  size="is-small"
                  class="is-transparent is-dark padding-xy-1" />

                <b-dropdown-item custom>
                  <div class="padding-y-1">
                    <b-radio v-model="sentFilter" native-value="all">
                      Show All
                    </b-radio>
                  </div>
                  <div class="padding-y-1">
                    <b-radio v-model="sentFilter" native-value="unsent">
                      Show Unsent
                    </b-radio>
                  </div>
                </b-dropdown-item>
                <b-dropdown-item class="has-text-right">
                  <small><a>close</a></small>
                </b-dropdown-item>
              </b-dropdown>
            </template>
            <template v-slot="props">
              <div v-if="props.row.invoiceSentDate">
                {{ props.row.invoiceSentDate | moment("M/D/YY") }}
              </div>
              <div v-else>
                <div v-if="(profile.type == 'jfe') && (profile.hasJfeAdminPermission)">
                  <b-button type="is-primary" size="is-small">
                    Send
                  </b-button>
                </div>
                <div v-else>
                  -
                </div>
              </div>
            </template>
          </b-table-column>
          <b-table-column v-slot="props" field="total" label="Total" numeric>
            <template v-if="props.row.totalRoyalty">
              {{ props.row.totalRoyalty | currency }}
            </template>
            <template v-else>
              --
            </template>
          </b-table-column>
          <b-table-column field="paid" label="Paid" centered>
            <template v-slot:header="{ column }">
              <span style="margin-right: 3px;">{{ column.label }}</span>

              <b-dropdown position="is-bottom-left" class="has-text-left" :mobile-modal="false">
                <b-button
                  slot="trigger"
                  icon-right="filter"
                  size="is-small"
                  class="is-transparent is-dark padding-xy-1" />

                <b-dropdown-item custom>
                  <div class="padding-y-1">
                    <b-radio v-model="paidFilter" native-value="all">
                      Show All
                    </b-radio>
                  </div>
                  <div class="padding-y-1">
                    <b-radio v-model="paidFilter" native-value="unpaid">
                      Show Unpaid
                    </b-radio>
                  </div>
                </b-dropdown-item>
                <b-dropdown-item class="has-text-right">
                  <small><a>close</a></small>
                </b-dropdown-item>
              </b-dropdown>
            </template>

            <template v-slot="props">
              <div v-if="props.row.invoicePaidDate">
                {{ props.row.invoicePaidDate | moment("M/D/YY") }}
              </div>
              <div v-else-if="daysOverdue(props.row) > 30">
                <span class="has-text-danger">Overdue</span>
              </div>
              <div v-else>
                <b-icon icon="times" type="is-danger" size="is-small" />
              </div>
            </template>
          </b-table-column>
          <b-table-column v-slot="props" field="review" label="Reviewed" centered>
            <b-icon v-if="props.row.invoiceDateReview" size="is-small" icon="check" type="is-success" />
            <b-icon v-else size="is-small" icon="times" type="is-danger" />
          </b-table-column>
          <!--<template slot="footer">
             <b-switch v-model="exportMode" type="is-success">
                Export
              </b-switch>
              <b-button v-if="exportMode" type="is-white is-rounded " style="margin-left: 15px" icon-left="download" @click="generateCsv">
                CSV
              </b-button>
          </template>-->
        </b-table>
      </div>
    </section>
  </div>
</template>

<script>

import Vue2Filters from 'vue2-filters'
import Moment from 'moment'
import InvoicesApi from '@/apis/InvoicesApi'

export default {
  name: 'Invoices',
  components: {},
  mixins: [Vue2Filters.mixin],
  data () {
    return {
      invoiceList: [],
      overdueDate: false,
      selectedLicensee: '',
      selectedLicenseeId: null,
      selectedLicenseeDivisionId: null,
      searchTerms: [],
      loaded: false,
      loadingInvoices: true,
      invoice: null
    }
  },
  computed: {
    profile () {
      return this.$store.state.profile
    },
    licensees () {
      return this.orderBy(this.$store.state.licensees, 'name')
    },
    licenseesById () {
      const mapped = {}

      this.licensees.forEach(licensee => {
        mapped[licensee.id] = licensee
      })
      return mapped
    },
    invoices () {
      if (this.profile.type === 'jfe') {
        return this.$store.state.invoices
      } else {
        return this.invoiceList
      }
    },
    now () {
      return Moment()
    },
    sentFilter: {
      get () {
        return ['sent', 'unsent'].find(status => this.searchTerms.some(term => term === 'email = ' + status)) || 'all'
      },
      set (val) {
        this.searchTerms = this.searchTerms.filter(term => !term.startsWith('email = '))

        if (val !== 'all') {
          this.searchTerms.push('email = ' + val)
        }
      }
    },
    paidFilter: {
      get () {
        return ['paid', 'unpaid'].find(status => this.searchTerms.some(term => term === 'status = ' + status)) || 'all'
      },
      set (val) {
        this.searchTerms = this.searchTerms.filter(term => !term.startsWith('status = '))

        if (val !== 'all') {
          this.searchTerms.push('status = ' + val)
        }
      }
    },
    invoicesFiltered () {
      return this.invoices.filter(invoice => {
        return this.searchTerms.length === 0 || this.searchTerms.some(term => {
          if (term.includes(' = ')) {
            const chunks = term.split(/ = /, 2)
            const property = chunks[0].trim()
            const value = chunks[1].trim()

            if (property === 'status') {
              if (value === 'unsent') {
                return !invoice.invoiceSentDate
              } else if (value === 'unpaid') {
                return !invoice.invoicePaidDate
              } else {
                return true
              }
            } else {
              return invoice[property] === value.toLowerCase()
            }
          } else {
            const haystack = [
              invoice.invoiceNumber,
              invoice.totalRoyalty
            ].join('|').toLowerCase()

            return haystack.includes(term.toLowerCase())
          }
        })
      }).filter(invoice => {
        if (this.profile.type !== 'jfe') {
          return invoice.invoiceSent
        } else {
          return true
        }
      }).filter(invoice => {
        if (this.selectedLicenseeId) {
          return invoice.licenseeId === this.selectedLicenseeId
        } else {
          return true
        }
      })
    }
  },
  async mounted () {
    this.$store.commit('setTitle', 'Invoices')
    this.$store.commit('setApp', 'royalty')
    await this.$store.dispatch('refreshLicensees')

    // resize table
    var nonTableElementsHeight = document.getElementById('navbar').clientHeight + document.getElementById('projects_tabletop').clientHeight + document.getElementById('projects_table').getElementsByClassName('level')[0].clientHeight
    var projectsTable = document.getElementById('projects_table').getElementsByClassName('table-wrapper')[0]
    projectsTable.style.minHeight = (window.innerHeight - nonTableElementsHeight) + 'px'
    projectsTable.style.paddingBottom = (document.getElementById('projects_table').getElementsByClassName('level')[0].clientHeight) + 'px'

    if (this.profile.type !== 'jfe') {
      this.selectedLicenseeId = this.profile.licenseeId
      this.invoiceList = await InvoicesApi.getInvoicesByLicenseeId(this.profile.licenseeId)
    } else {
      await this.$store.dispatch('refreshInvoices')
    }

    this.loadingInvoices = false
  },
  methods: {
    goToInvoice (invoice) {
      const routeData = this.$router.resolve({
        name: 'royalty_tracker_invoice',
        params: {
          invoiceId: invoice.invoiceId,
          licenseeId: invoice.licenseeId
        }
      })
      window.open(routeData.href, '_blank')
    },
    scrollTop (element) {
      this.$scrollTo(element, 500)
    },
    resetLicensee () {
      this.selectedLicenseeId = null
      this.selectedLicenseeDivisionId = null
      this.selectedPeriod = null
      this.selectedInvoice = null
    },
    momentDueDate (date) {
      if (date) {
        return Moment(date).format('M/D/YY')
      } else {
        return null
      }
    },
    hasSearchTerm (field, value) {
      return this.searchTerms.some(term => term === `${field} = ${value}`)
    },
    toggleSearchTerm (field, value) {
      if (this.hasSearchTerm(field, value)) {
        this.searchTerms = this.searchTerms.filter(term => term !== `${field} = ${value}`)
      } else {
        this.searchTerms.push(`${field} = ${value}`)
      }
    },
    daysOverdue (invoice) {
      if (!invoice.invoicePaid) {
        if (invoice.invoiceDueDate) {
          var d = new Date()
          var dueDateSplited = invoice.invoiceDueDate.substring(0, 10).split('-') // Convert the crap date from Mysql
          var dueDate = new Date(dueDateSplited[0], dueDateSplited[1] - 1, dueDateSplited[2])

          var diffInTime = d.getTime() - dueDate.getTime()
          var diffInDays = diffInTime / (1000 * 3600 * 24)
          return Math.floor(diffInDays)
        }
      }
      return 0
    }
  }
}

</script>
